<template>
  <el-container>
    <el-aside width="100px">
      <el-container class="botlst-box">
        <el-main>
          <div v-for="bot in botList" :key="bot.BotUid" @click="loadSenders(bot)"
            :class="{ 'bot-box': true, 'active': bot.BotUid == currentBot.BotUid }">
            <div v-if="bot.Count > 0">
              <el-badge :value="bot.Count">
                <img src="/static/img/QQ.png" class="bot-avatar" />
              </el-badge>
              <div class="bot-name">{{ bot.BotName }}</div>
            </div>
            <div v-else>
              <img src="/static/img/QQ.png" class="bot-avatar" />
              <div class="bot-name">{{ bot.BotName }}</div>
            </div>
          </div>
        </el-main>
        <!--底部内容区域-->
        <el-footer height="170px">
          <el-button @click="isShowAllSenders = !isShowAllSenders; loadSenders(currentBot);"
            :type="isShowAllSenders ? '' : 'primary'">看待处理</el-button>
          <el-button @click="handleBatchDelete()" type="danger" style="margin: 10px 0 0 0">忽略全部</el-button>
          <el-button @click="gotoBotAccount" style="margin: 10px 0 0 0">新 增QQ</el-button>
          <el-button @click="$refs.analyseConfig.showDialog()" style="margin: 10px 0">解析配置</el-button>
        </el-footer>
      </el-container>
    </el-aside>
    <el-aside width="160px">
      <el-container class="senders-box">
        <el-header height="40px">
          <el-input v-model="senderFilter" clearable @change="loadSenders(currentBot)" placeholder="搜索客户，货号"
            style="width: calc(100% - 10px);margin: 5px;" />
        </el-header>
        <el-main>
          <div v-infinite-scroll="loadNextSenders" infinite-scroll-distance="1">
            <div v-for="sender in senders" :key="sender.SenderUid" @click="loadMessages(sender)"
              :class="{ 'sender-box': true, 'active': sender == currentSender }">
              <div class="sender-avatar">
                {{ (sender.Nick ? sender.Nick : sender.SenderName).trim().charAt(0) }}
              </div>
              <div class="sender-name">
                {{ sender.SenderName }}
              </div>
              <div v-if="sender.Count > 0" class="sender-num">
                {{ sender.Count }}
              </div>
            </div>
          </div>
        </el-main>
      </el-container>
    </el-aside>
    <el-aside width="498px">
      <el-container class="message-box">
        <el-header height="40px">
          <div class="message-box-title" v-if="currentSender.SenderUid">
            <span class="el-icon-edit-outline" style="cursor: pointer;" @click="showSenderNickDialog" />
            {{ `${currentSender.Nick ? currentSender.Nick : currentSender.SenderName}(${currentSender.SenderUid})` }}
          </div>
        </el-header>
        <el-header height="40px">
          <el-input v-if="currentSender.SenderUid" v-model="currentSender.Remark" class="sender-remark-input" placeholder="请输入该客户的备注信息" prefix-icon=""
            clearable @change="savePurchaser('Remark')" />
        </el-header>
        <el-main class="message-box-content" style="margin-bottom: 10px;">
          <div class="message-box-top">- 已经到顶了 -</div>
          <div class="message-box-filter">
            <div><el-radio v-model="filterState" label="all" @input="handleFilterMessages">看全部</el-radio></div>
            <div style="margin-top:5px;"><el-radio v-model="filterState" label="0" @input="handleFilterMessages">看解析</el-radio></div>
          </div>
          <div v-for="message in filterMessages" :key="message.Id" style="position: relative">
            <div class="message-box-new" v-if="isShowMessageNew(message)">
              ——————————以下是当前解析结果——————————
            </div>
            <div class="message-box-time">{{ message.CreateTime }}</div>
            <el-container class="message-content-main">
              <el-aside :width="message.Pubsub == 0 ? '46px' : '30%'">
                <el-avatar v-if="message.Pubsub == 0" size="medium" class="left">{{ message.SenderName.trim().charAt(0)
                  }}</el-avatar>
              </el-aside>
              <el-main>
                <el-card shadow="never" :class="{ proxy: message.ProxyStatus == 1 }">
                  <template slot="header" v-if="message.Pubsub == 0">
                    <el-button v-show="isShowAnalyse(message)" type="text" icon="el-icon-picture-outline" style="color:#E6A23C;"
                      @click="handleImgAnalyse(message)">解析</el-button>
                    <el-button v-show="message.State == 0 || message.State == -1" type="text" icon="el-icon-edit-outline"
                      @click="handleTextAreaFocus(message)">编辑</el-button>
                    <el-button v-show="message.State == 0" type="text" icon="el-icon-delete" style="color: red;"
                      @click="handleChangeState(message, 2)">删除</el-button>
                    <el-button v-show="message.State == 2" type="text" icon="el-icon-refresh-left" style="color: red;"
                      @click="handleChangeState(message, 0)">撤回</el-button>
                  </template>
                  <div v-if="editId != message.Id" v-html="getContent(message)"
                    :style="{ 'white-space': 'pre-line', 'text-decoration': message.State == 2 ? 'line-through' : '' }" />
                  <div v-else>
                    <div style="color: #b6b6b6;font-size: 12px;text-align: center;">提示：鼠标点击其他地方将自动保存</div>
                    <el-input type="textarea" autosize placeholder="请输入内容" :ref="`edit${message.Id}`" v-model="message.Content"
                      @blur="(e) => handleTextAreaBlur(message)" />
                  </div>
                  <div v-if="message.State == 1" style="text-align: right;">
                    <el-button type="text" icon="el-icon-refresh-left" style="margin-right:5px;" @click="handleRecall(message)">取消订单</el-button>
                    <el-tag type="success">审核通过</el-tag>
                  </div>
                </el-card>
              </el-main>
              <el-aside :width="message.Pubsub == 1 ? '46px' : '30%'">
                <div class="message-success" v-if="message.Pubsub == 0" :style="isShowMessageNew(message) ? 'top:65px;' : 'top:35px;'">
                  <i v-if="message.State == 0" class="el-icon-success" style="color: #20b759;" />
                  <span v-if="message.State == 0 && message.Remark">待完善价格<br /></span>
                  <span v-if="message.State != 1 && message.NoAnalyseItemNos != ''">
                    部分<span style="background-color: #F56C6C;color: white;">商品</span>异常<br />
                  </span>
                  <span v-if="message.RecallState == 1" style="background-color: #F56C6C;color: white;">对方撤回消息<br /></span>
                  <el-button v-show="message.ProxyStatus == 0 && message.State == 0" type="primary" style="margin:0 10px 0 0;"
                    @click="handProxyShow(message)">设为代发</el-button>
                </div>
                <el-avatar v-else size="medium" class="right">我</el-avatar>
              </el-aside>
            </el-container>
          </div>
          <el-image :src='imgSrc' v-show="false" ref="imgPreview" :preview-src-list="[imgSrc]" />
          <div v-if="hasNewMessage" class="message-box-refresh" @click="loadMessages(currentSender)">
            您有新的消息
            <img src="/static/img/DoubleArrowDown.png" class="message-box-refresh-arrow" />
          </div>
        </el-main>
        <el-footer height="148px">
          <div style="box-shadow: 0 -7px  5px -5px #C3C3C3;">
            <div class="operate">
              <el-button type="danger" @click="handleBatchDelete(currentSender)">忽略消息</el-button>
              <el-popover placement="top-start" ref="qqface" title="选择表情" width="400" trigger="click">
                <div class="qq-face">
                  <img v-for="face in qqFaces" :key="face" :src="'https://img.yunguocang.com/qqface/' + face + '.png'" @click="sendFace(face)"
                    width="30" />
                </div>
                <img slot="reference" title="表情" src='https://img.yunguocang.com/qqface/14.png' width="25px" />
              </el-popover>
              <i type="primary" title="图片" @click="showPicDlg" class="el-icon-picture" />
            </div>
            <el-input v-model="messageContent" type="textarea" :rows="5" @keydown.native="sendInputEnter($event)" />
            <el-button type="primary" style="position:absolute;right:10px;bottom:10px;" @click="sendMessage()" :loading="sendFlag">回车/发送</el-button>
          </div>
        </el-footer>
      </el-container>
    </el-aside>
    <el-main>
      <el-container class="content-box">
        <el-main>
          <stall-trade-table ref="detailTable" :add-enable.sync="addEnable" @handleSave="loadMessages(currentSender)" />
        </el-main>
        <el-footer height="40px">
          <div style="float: left; padding: 5px 10px">
            <el-button type="primary" @click="save" :disabled="!addEnable">审核通过</el-button>
          </div>
          <span v-if="currentBot && currentBot.SocketState != 2" style="float: left;color: red;padding: 5px 10px;font-size: 20px;">插件异常，请检查插件</span>
          <el-switch v-if="$store.state.routes.systemSetting.WmsEnabled" style="float: right; margin: 10px;"
            v-model="$store.state.routes.systemSetting.CheckStock" active-text="看缺货"
            @change="$store.dispatch('routes/changeSettingItem', { CheckStock: $store.state.routes.systemSetting.CheckStock })" />
        </el-footer>
      </el-container>
    </el-main>
    <div class="page-dialog">
      <stall-config ref="analyseConfig" :field.sync="configField" :value.sync="analyseConfig" :level="0" source="analyse" :dialog="true">
      </stall-config>
      <el-dialog v-dialog-drag title="设置QQ昵称" width="400px" :visible="editNickForm.visible" :close-on-click-modal="false"
        @close="editNickForm.visible = false">
        <el-form :model="editNickForm" label-width="120px">
          <el-form-item label="QQ名称：">{{ editNickForm.name }}</el-form-item>
          <el-form-item label="QQ昵称：">
            <el-input v-model="editNickForm.nick" placeholder="请输入昵称" />
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button size="small" @click="editNickForm.visible = false">关闭</el-button>
          <el-button type="primary" size="small" @click="saveSenderNick">保存</el-button>
        </div>
      </el-dialog>
      <el-dialog :visible="proxyForm.visible" :title="proxyForm.title" @close="proxyForm.visible = false" :close-on-click-modal="false" width="800px">
        <el-form label-suffix="：" label-width="100px">
          <el-form-item label="报单内容">
            <span>{{ proxyForm.originContent }}</span>
          </el-form-item>
        </el-form>
        <el-descriptions :column="2" border>
          <el-descriptions-item span="2">
            <template slot="label">
              <i class="el-icon-user"></i>
              订单号
            </template>
            <el-input v-model="proxyForm.proxy.Tid" />
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-user"></i>
              收件人
            </template>
            <el-input v-model="proxyForm.proxy.ReceiverName" />
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-mobile-phone"></i>
              手机号
            </template>
            <el-input v-model="proxyForm.proxy.ReceiverMobile" />
          </el-descriptions-item>
          <el-descriptions-item span="2">
            <template slot="label">
              <i class="el-icon-office-building"></i>
              地址
            </template>
            <el-input v-model="proxyForm.proxy.ReceiverProvince" style="max-width: 110px;" />
            <el-input v-model="proxyForm.proxy.ReceiverCity" style="max-width: 110px;" />
            <el-input v-model="proxyForm.proxy.ReceiverDistrict" style="max-width: 110px;" />
            <el-input v-model="proxyForm.proxy.ReceiverAddress" style="max-width: calc(100% - 330px);" />
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-office-building"></i>
              商品
            </template>
            <el-input v-model="proxyForm.proxy.Content" type="textarea" />
          </el-descriptions-item>
        </el-descriptions>
        <div slot="footer" class="dialog-footer">
          <el-button @click="proxyForm.visible = false">取 消</el-button>
          <el-button type="primary" @click="handleProxySave">确 定</el-button>
        </div>
      </el-dialog>
      <el-dialog :visible="picForm.visible" title="选择图片发送" width="300px" @close="picForm.visible = false" :close-on-click-modal="false">
        <el-upload drag class="pic-upload" action="#" :on-change="handleChange" :auto-upload="false" :show-file-list="false">
          <img v-if="picForm.file" :src="picForm.file.url" class="avatar">
          <i v-else class="el-icon-plus"></i>
        </el-upload>
        <div slot="footer" class="dialog-footer">
          <el-button @click="picForm.visible = false">取消</el-button>
          <el-button size="small" type="primary" @click="sendPicture">发送</el-button>
        </div>
      </el-dialog>
    </div>
    <helper-center ref="helperCenter" position="qq" :visible="true"></helper-center>
  </el-container>
</template>

<script>
import { baseURL } from "@/config";
import { submit } from "@/api/base";
import { Loading } from "element-ui";
import StallTradeTable from "@/views/stall/trade/components/StallTradeTable.vue";
import StallConfig from "@/views/stall/trade/components/StallConfig.vue";
import HelperCenter from '@/views/stall/trade/components/HelperCenter.vue';
import axios from "axios";
import { botSend, fileToBase64 } from '@/utils/stallBot';

export default {
  name: "StallBotMessage",
  components: {
    StallTradeTable,
    StallConfig,
    HelperCenter
  },
  data() {
    return {
      socket: {
        timer: null,
        client: null
      },
      qqFaces: [],

      botList: [],
      currentBot: {},

      sendersParam: {
        currentPage: 1,
        loading: false,
        hasNext: true
      },
      senders: [],
      currentSender: {},
      editNickForm: {
        visible: false,
        name: '',
        nick: ''
      },
      senderFilter: '',
      isShowAllSenders: true,

      messages: [],
      loading: false,
      pageSize: 100,
      currentPage: 0,
      scrollHeight: 0,

      filterState: "all",
      filterMessages: [],

      editId: null,

      analyseMessages: [],

      sendFlag: false,
      messageContent: '',
      hasNewMessage: false,
      analyseConfig: {},
      configField: {},
      proxyForm: {
        visible: false,
        title: '设置代发信息',
        id: '',
        proxy: {}
      },
      addEnable: false,
      checkStock: false,
      picForm: {
        visible: false,
        file: null
      },
      imgSrc: ''
    };
  },
  metaInfo() {
    return {
      meta: [
        { name: "referrer", content: 'never' },
      ],
    };
  },
  async mounted() {
    var that = this;
    window.showImg = (obj) => {
      that.imgSrc = obj.src;
      if (that.imgSrc) {
        that.$refs.imgPreview.clickHandler();
      }
    }
    await this.loadAccount();
    this.getQQFaces();
    this.initWebSocket();
    clearInterval(this.socket.timer);
    this.setTimer();
  },
  beforeDestroy() {
    clearInterval(this.socket.timer);
    if (this.socket.client && this.socket.client.readyState == 1) {
      this.socket.client.close();
    }
  },
  methods: {
    //#region websocket
    setTimer() {
      if (this.socket.timer == null) {
        this.socket.timer = setInterval(() => {
          if (!this.socket.client || this.socket.client.readyState != 1) {
            this.initWebSocket();
          }
        }, 30 * 1000);
      }
    },
    initWebSocket() {
      // 实例化socket，这里的实例化直接赋值给this.socket.client.ws.close(); 完成通信后关闭WebSocket连接
      this.socket.client = new WebSocket(`${baseURL.replace('http://', 'ws://').replace('https://', 'wss://')}/qqPage`, [this.$store.getters["user/accessToken"]])

      let _this = this;
      //监听是否连接成功
      this.socket.client.onopen = () => {
        // console.log('socketClient open：' + this.socket.client.readyState);
        _this.getUnReadCount();
      }

      //接听服务器发回的信息并处理展示
      this.socket.client.onmessage = (data) => {
        // console.log('接收到来自服务器的消息：', data);
        _this.getUnReadCount();
      }

      //监听连接关闭事件
      this.socket.client.onclose = () => {
        //监听整个过程中websocket的状态
        // console.log('socketClient close：' + this.socket.client.readyState);
      }

      //监听并处理error事件
      this.socket.client.onerror = function (error) {
        console.log(error);
      }
    },
    //#endregion
    async getQQFaces() {
      await axios.get(`./static/json/face.json`).then((json) => {
        this.qqFaces = json.data;
      });
    },
    async getUnReadCount() {
      if (!this.currentBot.BotUid) return;

      const { data } = await submit(`/api/StallBotMessage/GetUnReadCount`, {
        BotType: this.currentBot.BotType,
        BotUid: this.currentBot.BotUid,
        Keyword: this.senderFilter
      });
      this.botList.forEach(bot => {
        const arrBot = data.filter(t => t.Uid == bot.BotUid);
        if (arrBot.length <= 0) {
          bot.SocketState = 5;
          bot.Count = 0;
        }
        else {
          bot.SocketState = arrBot[0].SocketState;
          bot.StoreID = arrBot[0].StoreID;
          bot.Count = arrBot[0].Count;
          if (this.currentBot.BotUid == bot.BotUid) {
            arrBot[0].Senders.forEach(sender => {
              if (!this.senders.some(e => e.SenderUid == sender.SenderUid)) {
                sender.Nick = '';
                sender.Remark = '';
                this.senders.push(sender);
              }
            });

            this.senders.forEach((sender) => {
              const arrSender = arrBot[0].Senders.filter((item) => item.SenderUid == sender.SenderUid);
              if (arrSender.length > 0) {
                sender.SenderName = arrSender[0].SenderName;
                sender.Count = arrSender[0].Count;
                sender.LastTime = arrSender[0].LastTime;
                sender.LastContent = arrSender[0].LastContent;
              } else {
                sender.Count = 0;
              }
            });
          }
        }
      });
      const lastMessage = this.messages[this.messages.length - 1];
      if (lastMessage) {
        this.hasNewMessage = this.currentSender.LastTime > lastMessage.CreateTime;
      }
      this.botList.sort((a, b) => b.Count - a.Count);
      this.senders.sort((a, b) => Date.parse(b.LastTime) - Date.parse(a.LastTime));
    },
    handleFilterMessages(value) {
      if (value == "all") {
        this.filterMessages = this.messages;
      } else {
        this.filterMessages = this.messages.filter((item) => {
          return item.Pubsub == 0 && item.State == value;
        });
      }
    },
    async loadAccount() {
      const { data } = await submit("/api/StallBotAccount/GetList");
      data.forEach((item) => (item.Count = item.MessageCount)); //初始化待审消息数
      data.forEach(bot => bot.SocketState = 5);
      this.botList = data;
      this.botList.sort((a, b) => b.Count - a.Count);

      if (this.botList.length > 0) {
        const bot = this.botList[0];
        await this.loadSenders(bot);
      }
    },
    gotoBotAccount() {
      this.$router.push({ path: "/base/bot-account" });
    },
    handleBatchDelete(sender) {
      const msg = sender ? `您是否要忽略 ${sender.SenderName} 的待处理消息` : `您是否要忽略 全部QQ 的待处理消息?`;
      this.$confirm(msg, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        const result = await submit(`/api/stallBotMessage/batchDelete`, sender ? sender : {});
        if (result.success) {
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          if (sender) {
            this.loadMessages(sender);
          } else {
            await this.loadAccount();
          }
        }
      });
    },
    showSenderNickDialog() {
      if (!this.currentSender.SenderUid) return;
      this.editNickForm.name = this.currentSender.SenderName;
      this.editNickForm.nick = this.currentSender.Nick;
      this.editNickForm.visible = true;
    },
    async saveSenderNick() {
      this.currentSender.Nick = this.editNickForm.nick;
      this.savePurchaser('Nick');
      this.editNickForm.visible = false;
      this.$refs.detailTable.analyse();
    },
    async savePurchaser(type) {
      if (!this.currentSender.Purchaser) {
        return;
      }
      switch (type.toLowerCase()) {
        case 'nick':
          this.currentSender.Purchaser.Nick = this.currentSender.Nick;
          break;
        case 'remark':
          this.currentSender.Purchaser.Remark = this.currentSender.Remark;
          break;
      }
      await submit('/api/stall/savePurchaser', { changeType: type, purchaser: this.currentSender.Purchaser });
    },
    async loadNextSenders() {
      if (this.senders.length == 0 || this.sendersParam.loading || !this.sendersParam.hasNext) {
        return;
      }
      this.sendersParam.loading = true;
      this.loadSenders(this.currentBot, true);
    },
    async loadSenders(bot, next = false) {
      this.currentBot = bot;
      if (!next) {
        this.currentSender = {};
        this.senders = [];
        this.messages = [];
        this.filterState = "all";
        this.filterMessages = [];
        this.editId = null;
        this.analyseMessages = [];
        this.sendersParam.currentPage = 1;
      }
      else {
        this.sendersParam.currentPage++;
      }

      const { data } = await submit('/api/stallBotMessage/getSenders', {
        currentPage: this.sendersParam.currentPage,
        pageSize: 20,
        BotType: this.currentBot.BotType,
        BotUid: this.currentBot.BotUid,
        Keyword: this.senderFilter,
        showAll: this.isShowAllSenders
      });
      if (data.length < 20) {
        this.sendersParam.hasNext = false;
      }
      else {
        this.sendersParam.hasNext = true;
      }

      data.forEach(sender => {
        const arrSender = this.senders.filter(e => e.SenderUid == sender.SenderUid);
        if (arrSender.length <= 0) {
          sender.Nick = '';
          sender.Remark = '';
          this.senders.push(sender);
        }
        else {
          arrSender[0].SenderName = sender.SenderName;
          arrSender[0].Count = sender.Count;
          arrSender[0].LastTime = sender.LastTime;
          arrSender[0].LastContent = sender.LastContent;
        }
      });
      if (!next && this.senders.length > 0) {
        const sender = this.senders[0];
        await this.loadMessages(sender);
      }
      if (next) {
        this.senders.sort((a, b) => Date.parse(b.LastTime) - Date.parse(a.LastTime));
      }
      this.$nextTick(() => {
        this.sendersParam.loading = false;
      });
    },
    async loadMessages(sender, loadingDetail = true) {
      if (loadingDetail) {
        this.addEnable = false;
      }
      if (!sender.Purchaser) {
        const { data } = await submit("/api/stall/getPurchaser", sender);
        sender.Purchaser = data;
        sender.Nick = data.Nick;
        sender.Remark = data.Remark;
      }
      this.currentPage = 1;
      this.scrollHeight = 0;
      this.currentSender = sender;
      this.filterState = "all";
      this.messageContent = '';

      const { data } = await submit("/api/stallBotMessage/getList", {
        botUid: this.currentBot.BotUid,
        senderUid: this.currentSender.SenderUid,
        pageSize: this.pageSize,
        currentPage: this.currentPage,
      });
      this.messages = data.datas; //赋值
      this.filterMessages = this.messages;
      this.hasNewMessage = false; //无新消息

      if (data.datas.length > 0) {
        this.currentPage++;
        this.$nextTick(() => {
          const box = document.getElementsByClassName("message-box-content")[0];

          box.scrollTop = box.scrollHeight; //滚动条位置-底部

          box.onscroll = async (e) => {
            this.scrollHeight = e.target.scrollHeight;

            //滚动条宽度。忽略2(两边border的宽度)
            if (e.target.offsetWidth - e.target.clientWidth <= 2) {
              return;
            }

            if (e.target.scrollTop > 0) return;

            if (this.loading) return;
            this.loading = true; //防止重复加载
            await this.appendMessage(this.currentSender.SenderUid);
          };
        });
      }
      if (loadingDetail) {
        this.handleAnalyseMessages(); //解析
      }
    },
    async handleAnalyseMessages() {
      this.$refs.detailTable.init();
      let ids = "";

      this.analyseMessages = this.messages.filter((item) => {
        return item.Pubsub == 0 && item.State == 0;
      });

      if (this.analyseMessages.length <= 0) return;

      for (var item of this.analyseMessages) {
        item = JSON.parse(JSON.stringify(item));

        if (!ids) {
          ids = item.Id;
        } else {
          ids += `,${item.Id}`;
        }
      }

      //提交解析
      this.$refs.detailTable.init({ ids: ids, sender: this.currentSender, storeID: this.currentBot.StoreID });
    },
    async appendMessage(senderUid) {
      const { data } = await submit("/api/stallBotMessage/getList", {
        senderUid,
        pageSize: this.pageSize,
        currentPage: this.currentPage,
      });

      if (data.datas.length > 0) {
        this.messages = [...data.datas, ...this.messages];
        this.filterMessages = this.messages;
        this.handleAnalyseMessages(); //重新分析

        this.currentPage++;
        this.$nextTick(() => {
          const box = document.getElementsByClassName("message-box-content")[0];
          box.scrollTop = box.scrollHeight - this.scrollHeight; //滚动条位置-定位
        });
      }

      this.loading = false; //防止重复加载
    },
    isShowMessageNew(message) {
      const unReadMessages = this.messages.filter(item => item.Pubsub == 0 && item.State == 0);
      if (unReadMessages.length <= 0) return false;
      return message == unReadMessages[0];
    },
    isShowAnalyse(message) {
      if (message.State == 0 || message.State == -1) {
        return message.RawContent.indexOf('CQ:image') > 0;
      }
      return false;
    },
    getContent(message) {
      let content = message.Content;
      if (content.indexOf('<img ') >= 0) {
        content = content.replace('<img ', '<img onclick="showImg(this)" class="preview_img"');
      }
      if (message.State != 1 && message.NoAnalyseItemNos) {
        message.NoAnalyseItemNos.split(',').forEach(itemNo => {
          content = content.replace(itemNo, `<span style="background-color:#F56C6C;color:white;">${itemNo}</span>`);
        });
      }
      return content;
    },
    async handleImgAnalyse(message) {
      if (message.RawContent.indexOf('CQ:image') > 0) {
        const loadingInstance = Loading.service({
          target: "#main-wrapper",
          text: "正在解析中...",
        });
        const { data } = await submit(`/api/stallBotMessage/imgAnalyse?messageId=${message.Id}`).finally(() => {
          loadingInstance.close();
        });
        this.loadMessages(this.currentSender);
      }
    },
    handleTextAreaFocus(message) {
      if (message.ProxyStatus == 1) {
        this.handProxyShow(message);
        return;
      }
      this.editId = message.Id;
      this.$nextTick(() => {
        const textAreaRef = this.$refs[`edit${message.Id}`][0];
        textAreaRef.resizeTextarea();
        textAreaRef.focus();
      })
    },
    async handleTextAreaBlur(message) {
      const { code, msg } = await submit("/api/stallBotMessage/changeContent", {
        Id: message.Id,
        Content: message.Content,
      });

      if (code == 200) {
        this.$message({ type: "success", message: msg });
      } else {
        this.$message({ type: "error", message: msg });
      }
      this.editId = null;
      this.loadMessages(this.currentSender);
    },
    async handleChangeState(message, state) {
      const result = await submit("/api/stallBotMessage/changeState", {
        state,
        ids: message.Id,
      });
      if (result.success) {
        message.State = state;
        this.handleAnalyseMessages();
        this.$message({ type: "success", message: `操作成功!` });
      }
    },
    async handleRecall(message) {
      const result = await submit("/api/stallBotMessage/recall", {
        TradeIds: message.TradeIds,
        RecallIds: message.RecallIds
      });
      if (result.success) {
        this.$message({ type: "success", message: `操作成功!` });
        this.loadMessages(this.currentSender);
      }
    },
    sendInputEnter(event) {
      if (event.keyCode === 13) {
        event.preventDefault();
        this.sendMessage();
        return false;
      }
    },
    //发送表情
    async sendFace(face) {
      if (!this.currentBot || !this.currentSender)
        return;
      this.$refs.qqface.doClose();
      let res = await botSend("face", face, {
        botType: this.currentBot.BotType,
        botUid: this.currentBot.BotUid,
        senderUid: this.currentSender.SenderUid
      });
      if (res) {
        this.loadMessages(this.currentSender, false);
      }
    },
    //发送消息
    async sendMessage() {
      if (!this.currentBot || !this.currentSender)
        return;
      let content = this.messageContent;
      if (this.sendFlag) return;

      this.sendFlag = true;
      let res = await botSend("text", content, {
        botType: this.currentBot.BotType,
        botUid: this.currentBot.BotUid,
        senderUid: this.currentSender.SenderUid
      });
      this.sendFlag = false;
      if (res) {
        this.messageContent = '';
        this.loadMessages(this.currentSender, false);
      }
    },
    //显示图片选择弹窗
    showPicDlg() {
      this.picForm.file = null;
      if (!this.currentBot || !this.currentSender)
        return;
      this.picForm.visible = true;
    },
    //选择图片修改触发
    async handleChange(file, fileList) {
      this.picForm.file = { raw: file.raw, url: URL.createObjectURL(file.raw) };
    },
    //发送图片
    async sendPicture() {
      if (!this.picForm.file) {
        this.$message({ type: "error", message: `请选择图片！` });
        return;
      }
      let that = this;
      fileToBase64(this.picForm.file.raw)
        .then(async res => {
          let sendRes = await botSend("img", res, {
            botType: that.currentBot.BotType,
            botUid: that.currentBot.BotUid,
            senderUid: that.currentSender.SenderUid
          });
          that.picForm.visible = false;
          that.picForm.file = [];
          that.picForm.url = [];
          if (sendRes) {
            that.$message({ type: 'success', message: '发送成功' });
            that.loadMessages(that.currentSender, false);
          }
        })
        .catch(error => {
          console.error(error)
        });
    },
    async save() {
      this.analyseMessages = this.messages.filter((item) => {
        return item.Pubsub == 0 && item.State == 0;
      });

      if (this.analyseMessages.length <= 0) return;
      this.$refs.detailTable.handleAddOrder();
    },
    handProxyShow(message) {
      this.proxyForm.visible = true;
      this.proxyForm.originContent = message.Content;
      this.proxyForm.id = message.Id;
      this.proxyForm.proxy = message.ProxyResult;
    },
    async handleProxySave() {
      await submit("/api/stall/proxy/save", {
        trade: {
          Proxy: this.proxyForm.proxy, MessageIDs: [this.proxyForm.id]
        },
        saveType: 0
      });
      this.proxyForm = { visible: false, title: '设置代发信息', id: '', proxy: {} };
      this.loadMessages(this.currentSender);
      this.$message({ type: "success", message: `操作成功!` });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .el-textarea__inner {
    overflow: hidden !important;
  }

  .preview_img {
    cursor: pointer;
  }
}

.botlst-box {
  border: 1px solid lightgray;
  text-align: center;
  background: #eceae9 !important;

  ::v-deep {
    .el-main {
      overflow-y: auto;
    }
  }


  .bot-box {
    cursor: pointer;
    margin: 10px 5px;

    .bot-avatar {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      border: 1px solid lightgray;
    }

    .bot-name {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box !important;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      word-break: break-all;
    }
  }

  .active {
    .bot-avatar {
      border: 1px solid #409eff;
    }

    .bot-name {
      color: #409eff;
    }
  }

  .bot-box:hover {
    .bot-avatar {
      border: 1px solid #409eff;
    }

    .bot-name {
      color: #409eff;
    }
  }
}


.senders-box {
  background: #f6f6f6 !important;
  border: 1px solid lightgray;

  ::v-deep {
    .el-main {
      overflow-y: auto;
    }
  }

  .sender-box {
    cursor: pointer;
    box-sizing: border-box;
    position: relative;
    height: 55px;
    line-height: 55px;
    padding: 10px 5px;
    margin-bottom: 2px;

    .sender-avatar {
      box-sizing: border-box;

      color: #0075e3;
      background: #e2edf7;

      width: 35px;
      height: 35px;
      line-height: 35px;
      border-radius: 50%;
      border: 1px solid #d9ecff;

      text-align: center;
    }

    .sender-name {
      position: absolute;
      top: 0;
      left: 45px;
      width: 85px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 12px;
    }

    .sender-num {
      position: absolute;
      top: 20px;
      left: 120px;
      background: #F56C6C;
      border: 1px solid #F56C6C;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      color: snow;
      font-size: 12px;
      justify-content: center;
      align-items: center;
      text-align: center;
      display: flex;
    }
  }

  .active {
    background: #409eff;

    .sender-avatar {
      background: white;
    }

    .sender-name {
      color: white;
    }
  }

  .sender-box:hover {
    background: #409eff;

    .sender-avatar {
      background: white;
    }

    .sender-name {
      color: white;
    }
  }
}

.message-box {
  box-sizing: border-box;
  border: 1px solid lightgray;
  height: 100%;
  background: #f1f1f1 !important;
  position: relative;

  ::v-deep {
    .el-main {
      overflow: auto;
    }
  }

  &-title {
    line-height: 40px;
    text-align: center;
    background: #f6f6f6;
    font-size: 18px;
    font-weight: 650;
  }

  .sender-remark-input {
    ::v-deep {
      .el-input__inner {
        border: 0px;
        outline: none;
        color: #2789E7;
        background-color: #C3E1FF;
      }
    }

    ::placeholder {
      color: #90C4F7;
    }

    ::-webkit-input-placeholder {
      /* Edge */
      color: #90C4F7;
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #90C4F7;
    }
  }

  &-top {
    text-align: center;
    margin: 10px 0;
    color: lightgray;
  }

  &-filter {
    position: absolute;
    right: 15px;
    top: 80px;
    z-index: 1;
  }

  &-new {
    text-align: center;
    margin: 10px 0;
    color: #b4d5ff;
  }

  &-time {
    text-align: center;
    margin: 10px 0;
    color: lightgray;
  }

  &-content {
    overflow: auto;
    box-sizing: border-box;

    .message-box-footer {
      position: absolute;
      bottom: 0;
      width: 498px;
    }

    .message-content-main {
      background-color: inherit;

      &>aside,
      &>main {
        border: 0;

        .el-avatar {

          color: #409eff;
          background: #ecf5ff;
          border: 1px solid #d9ecff;

          text-align: center;

          &.left {
            background: #409eff;
            color: white;
            float: left;
          }

          &.right {
            float: right;
            border: 1px solid #409eff;
          }
        }

        .el-card {
          border-radius: 5px;

          button {
            padding: 0;
            padding-top: 5px;
          }

          ::v-deep {
            .el-card__header {
              padding: 0;
              margin-right: 10px;
              text-align: right;
              border-bottom: 0;
              min-height: 10px;
            }

            .el-card__body {
              padding: 5px 10px;
            }
          }

          &.proxy {
            position: relative;

            &::before {
              content: "代";
              height: 20px;
              width: 20px;
              position: absolute;
              box-sizing: border-box;
              top: 0;
              left: 0;
              color: #ffffff;
              background: #6ab319;
              border-bottom-right-radius: 10px;
              padding-left: 2px;
              font-size: 14px;
              z-index: 50;
            }
          }
        }

      }
    }
  }

  &-refresh {
    cursor: pointer;
    position: absolute;
    bottom: 150px;
    right: 15px;
    color: #79CA4F;
    width: 90px;
    height: 35px;
    line-height: 35px;
    margin: 5px;
    padding: 0 5px 0 25px;
    border-radius: 8px;
    box-shadow: 2px 2px 5px 2px #CACACA;
    background-color: #FFFFFF;
    text-align: center;

    &-arrow {
      width: 25px;
      height: 25px;
      position: absolute;
      top: 4px;
      left: 5px;
    }
  }
}

.content-box {
  border: 0 !important;

  .el-main {
    .stall-trade-table {
      height: 100%;
    }
  }
}

.qq-face {
  width: 400px;
  height: 200px;
  overflow-y: scroll;

  &>img:hover {
    background-color: #e8e8e8;
    cursor: pointer;
  }
}

.operate {
  padding: 3px;

  button {
    padding: 8px;
    font-size: 14px;
  }

  img,
  i {
    margin-left: 10px;
    font-size: 25px;
    vertical-align: middle;
    cursor: pointer;
  }
}

.pic-upload {
  text-align: center;

  ::v-deep {
    .el-upload-dragger {
      width: 178px;
      height: 178px;

      .el-icon-plus {
        font-size: 28px;
        width: 100%;
        height: 100%;
        line-height: 178px;
      }

      .avatar {
        width: 178px;
        height: 178px;
      }
    }
  }
}
</style>
